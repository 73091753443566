import React, { useRef, useLayoutEffect, useEffect, useState } from "react"
import Logo from "../images/logo.svg"
import { Link, useStaticQuery, graphql } from "gatsby"
import css from "@emotion/css"
import { Collapse } from "react-collapse"

export default function Header() {
  const { products, web } = useStaticQuery(graphql`
    {
      web: contentfulWebsiteInfo {
        logo {
          file {
            url
          }
        }
      }
      products: allContentfulProduct(
        filter: { menuItemPosition: { regex: "/Dropdown/" } }
      ) {
        nodes {
          slug
          name
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)

  const Links = [
    {
      text: "Business Loans",
      children: [
        ...products.nodes.map(({ slug, name }) => ({
          to: `/business-loans/${slug}/`,
          text: name.replace("[newline]", ""),
        })),
        {
          to: `/other-products/`,
          text: "Other products",
        },
      ],
    },
    {
      to: "/partner-program/",
      text: "Partner Program",
    },

    {
      to: "/apply/",
      text: "Apply Now",
      button: true,
    },
  ]

  return (
    <div className="bg-white border-b shadow border-gray-300">
      <div className="container py-3 mx-auto flex flex-wrap items-center justify-between">
        <Link to="/">
          <img
            css={css`
              width: 222px;
              height: 59px;
            `}
            src={web.logo.file.url}
            alt="Funding North America Logo"
          />
        </Link>
        <div className="md:block hidden">
          {Links.map((item, i) => {
            var Tag = item.children ? "div" : Link
            return (
              <React.Fragment key={i}>
                {item.button ? (
                  <Link
                    to={item.to}
                    className="ml-6 uppercase  bg-brand-4 text-white py-2 px-5 first:ml-0"
                  >
                    {item.text}
                  </Link>
                ) : (
                  <Tag
                    to={item.children ? "" : item.to}
                    onClick={e => {
                      setIsOpen(false)
                    }}
                    className="ml-6 uppercase inline-block relative group  first:ml-0"
                  >
                    {item.text}
                    {item.children && (
                      <>
                        <Arrow></Arrow>
                        <div className="hidden group-hover:block absolute w-56 flex flex-col border bg-white shadow-lg p-2 left-0 ">
                          {item.children.map((item, i) => (
                            <Link
                              to={item.to}
                              key={i}
                              onClick={e => {
                                setIsOpen(false)
                              }}
                              activeClassName={"text-white bg-blue-800"}
                              className="my-1 uppercase block hover:text-white hover:bg-blue-800   p-2"
                            >
                              {item.text}
                            </Link>
                          ))}
                        </div>
                      </>
                    )}
                  </Tag>
                )}
              </React.Fragment>
            )
          })}
        </div>
        <a
          href="#"
          className="block md:hidden"
          onClick={e => {
            setIsOpen(o => !o)
          }}
          css={css`
            span {
              display: block;
              height: 3px;
              width: 1.5rem;
              margin: 0.2rem 0;
            }
          `}
        >
          <span className="bg-brand-4 "></span>
          <span className="bg-brand-4 "></span>
          <span className="bg-brand-4 "></span>
        </a>
      </div>
      <Dropdown isOpen={isOpen}>
        <ul className="md:hidden list-none text-center pt-0 pb-8 flex flex-col justify-start">
          {Links.map((item, i) => {
            const Tag = item.children ? "div" : Link

            return (
              <React.Fragment key={i}>
                {item.button ? (
                  <li className=" py-3">
                    <Link
                      to={item.to}
                      className="uppercase  bg-brand-4 text-white py-2 px-5 "
                    >
                      {item.text}
                    </Link>
                  </li>
                ) : (
                  <li className=" py-3">
                    <Tag
                      to={item.to}
                      onClick={e => {
                        !item.children && setIsOpen(false)
                      }}
                      className="uppercase  "
                    >
                      {item.text}
                    </Tag>

                    {item.children && (
                      <div className="px-4 mt-2">
                        {" "}
                        <ul className="bg-brand-light2 py-2">
                          {item.children.map((link, i) => (
                            <li className="my-1" key={i}>
                              <Link
                                onClick={e => setIsOpen(false)}
                                to={link.to}
                              >
                                {link.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                )}
              </React.Fragment>
            )
          })}
        </ul>
      </Dropdown>
    </div>
  )
}

const Dropdown = ({ children, isOpen = false }) => {
  return <Collapse isOpened={isOpen}>{children}</Collapse>
}

const Arrow = ({ props }) => {
  return (
    <svg
      className="fill-current relative text-gray-900 w-6 h-6 inline-block"
      viewBox="0 0 24 24"
      style={{ top: "-2px" }}
    >
      <path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z" />
    </svg>
  )
}
