import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header></Header>
        <div className="flex-grow">{children}</div>
        <Footer></Footer>
      </div>{" "}
    </>
  )
}
