import React from "react"
import css from "@emotion/css"
import buildingIcon from "../images/building-icon.png"
import phoneIcon from "../images/phone-icon.png"
import clockIcon from "../images/clock-icon.png"
import mailIcon from "../images/mail-icon.png"
import chatIcon from "../images/chat-icon.png"
import Facebook from "../images/facebook.png"
import Instagram from "../images/Instagram.png"
import Linkedin from "../images/linkedin.png"
import { useStaticQuery, graphql, Link } from "gatsby"
export default function Footer() {
  const { products, info } = useStaticQuery(graphql`
    {
      products: allContentfulProduct(
        filter: { menuItemPosition: { regex: "/Dropdown/" } }
      ) {
        nodes {
          id
          name
          slug
        }
      }
      info: contentfulWebsiteInfo {
        phone
        email
        hoursOfOperation
        facebook
        instagram
        location {
          text: location
        }
        linkedin
      }
    }
  `)

  return (
    <div className="bg-brand-light2">
      <div className="container py-12">
        <div className="flex justify-between flex-col sm:flex-row">
          <div>
            <h3 className="text-xl uppercase font-semibold text-blue-800">
              Business Loans
            </h3>
            <ul>
              {products.nodes.map((product, i) => (
                <li className="my-1" key={i}>
                  <Link to={`/business-loans/${product.slug}`}>
                    {product.name.replace("[newline]", "")}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div></div>
          <div className="mt-4 sm:mt-0">
            <ul>
              <li className="my-1 relative pl-6 ">
                <img
                  className="absolute  "
                  css={css`
                    top: 0.45rem;
                    left: 0rem;
                  `}
                  src={chatIcon}
                ></img>{" "}
                Live Chat
              </li>
              <li className="my-1 relative pl-6">
                <img
                  className="absolute  "
                  css={css`
                    top: 0.45rem;
                    left: 0rem;
                  `}
                  src={phoneIcon}
                ></img>{" "}
                Call Us: <a href={`tel:${info.phone[0]}`}>{info.phone[1]}</a>
              </li>
              <li className="my-1 relative pl-6">
                {" "}
                <img
                  className="absolute  "
                  css={css`
                    top: 0.45rem;
                    left: 0rem;
                  `}
                  src={mailIcon}
                ></img>
                Email: <a href={`mailto:${info.email}`}>{info.email}</a>
              </li>
              <li className="my-1 relative pl-6">
                <img
                  className="absolute  "
                  css={css`
                    top: 0.45rem;
                    left: 0rem;
                  `}
                  src={clockIcon}
                ></img>{" "}
                {info.hoursOfOperation}
              </li>
            </ul>
          </div>
          <div className="my-1 relative pl-6 leading-relaxed">
            {" "}
            <img
              className="absolute  "
              css={css`
                top: 0.45rem;
                left: 0rem;
              `}
              src={buildingIcon}
            ></img>
            <div
              dangerouslySetInnerHTML={{
                __html: info.location.text.replace(/\n/g, "<br/>"),
              }}
            ></div>
          </div>
        </div>
        <div className="flex items-center sm:justify-center py-6">
          <a href={info.facebook} className="sm:mr-4 sm:ml-4  ml-0 mr-6">
            <img src={Facebook}></img>
          </a>
          <a href={info.instagram} className="sm:mr-4 sm:ml-4  ml-0 mr-6">
            <img src={Instagram}></img>
          </a>
          <a href={info.linkedin} className="sm:mr-4 sm:ml-4  ml-0 mr-6">
            <img src={Linkedin}></img>
          </a>
        </div>
      </div>
    </div>
  )
}
